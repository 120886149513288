import React from "react";
import {
  Dimm,
  PageInfo,
  SlidePageWrap,
  SubKdsContent,
  SubKdsWrap,
} from "ComponentsFarm/styles/common";
import SubKdsHeader from "ComponentsFarm/SubKdsHeader";
import Receipt from "ComponentsFarm/Receipt";
import { ISubkdsListRes } from "ApiFarm/interface/subkds";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { fetchSubKdsList } from "ApiFarm/subkds";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/types";
import { Mousewheel } from "swiper";
import "swiper/css";
import SwiperSlideItem from "ComponentsFarm/SwiperSlideItem";
import { authStore, prepListStore } from "MobxFarm/store";
import { useHistory } from "react-router-dom";
import { fetchMyStore } from "ApiFarm/auth";
import GlobalModal from "ComponentsFarm/element/GlobalModal";
import { toJS } from "mobx";

export const menu = [
  { idx: 1, areaName: "AISTT" },
  { idx: 2, areaName: "STATION" },
  { idx: 4, areaName: "튀김기" },
  { idx: 8, areaName: "음료" },
];

function Home() {
  const history = useHistory();
  const [infoStatus, setInfoStatus] = useState({
    ing: 0,
    wait: 0,
  });

  const [areaNumber, setAreaNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const Grid = 9;

  // useQuery(["selected-store"], fetchMyStore, {
  //   enabled: authStore.isLoggedIn,
  //   onSuccess: (res) => {
  //     authStore.selected_store_idx = res.selected_store_idx;
  //     authStore.selected_store_name = res.selected_store_name;
  //   },
  // });

  //셋팅 값 가져오기
  useEffect(() => {
    if (!authStore.isLoggedIn) {
      return history.push("/");
    }
  }, [history]);

  // 영수증 리스트
  const { data, refetch, isFetching } = useQuery<ISubkdsListRes, AxiosError>(
    ["subKdsListData", areaNumber],
    () =>
      fetchSubKdsList(areaNumber, {
        current_page_number: 1,
        per_page_number: 99,
      }),
    {
      //refetchInterval: 1000 * 60 * 15,
      refetchInterval: 1000 * 5,
      refetchIntervalInBackground: true,
    }
  );

  useEffect(() => {
    if (data) {
      setInfoStatus({
        ing: data.list.filter((el) => el.process_status === 1).length,
        wait: data.list.filter((el) => el.process_status === 0).length,
      });
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [areaNumber]);

  const totalCount = useMemo(
    () => Math.ceil(Number(data?.list?.length) / Grid),
    [data?.list?.length]
  );

  const indexOfLastItem = currentPage * Grid;
  const indexOfFirstItem = indexOfLastItem - Grid;
  const currentItems = data?.list?.slice(indexOfFirstItem, indexOfLastItem);

  const soundEffectPlay = useCallback((src: string) => {
    const audio = new Audio(src);
    audio.play();
  }, []);

  return (
    <div>
      <SubKdsWrap>
        <SubKdsHeader
          infoStatus={infoStatus}
          areaNumber={areaNumber}
          setAreaNumber={setAreaNumber}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
        />

        <SubKdsContent>
          {data?.list?.length === 0 ? (
            <div className="noOrder">
              <div className="logo">
                <img src="/images/intro-logo.svg" alt="GOPIZZA" />
              </div>
            </div>
          ) : (
            <SlidePageWrap className="SlidePage">
              {currentItems?.length !== 0 &&
                currentItems?.map((el) => (
                  <Receipt
                    key={el.receipt_item_contents_idx}
                    data={el}
                    areaNumber={areaNumber}
                    setInfoStatus={setInfoStatus}
                    soundEffectPlay={soundEffectPlay}
                  />
                ))}
            </SlidePageWrap>
          )}
        </SubKdsContent>

        <GlobalModal
          areaNumber={areaNumber}
          soundEffectPlay={soundEffectPlay}
        />
      </SubKdsWrap>
    </div>
  );
}

export default Home;
