import { ISubkdsListItem, ISubkdsListRes } from "ApiFarm/interface/subkds";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dimm, Popup, ReceiptWrap } from "./styles/common";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchSubKdsProcess } from "ApiFarm/subkds";
import { kdsSettingStore, prepListStore, prepStore } from "MobxFarm/store";
import { toast } from "react-toastify";
import RippleButton from "./RippleButton";
import { IPrepProcessReq } from "ApiFarm/interface/prep";
import { fetchPrepProcess } from "ApiFarm/prep";
import { toJS } from "mobx";
import { observer } from "mobx-react";

dayjs.extend(duration);

function Receipt({
  data,
  areaNumber,
  setInfoStatus,
  soundEffectPlay,
}: {
  data: ISubkdsListItem;
  areaNumber: number;
  setInfoStatus: Dispatch<
    SetStateAction<{
      ing: number;
      wait: number;
    }>
  >;
  soundEffectPlay: (src: string) => void;
}) {
  const queryClient = useQueryClient();
  const tick = useRef<NodeJS.Timer>();
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const timer = useRef<NodeJS.Timer | null>(null);

  const [showTime, setShowTime] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [time, setTime] = useState(0);
  const [load, setLoad] = useState(true);
  const [bell, setBell] = useState(false);
  const [retry, setRetry] = useState(false);
  const [showPrepConfirm, setShowPrepConfirm] = useState(false);

  const processStartTimeRef = useRef<Date | null>(null);

  const passedTime = useCallback((orderTime: string) => {
    // 숫자를 시간으로 표현
    const startTime = dayjs(orderTime);
    var endTime = dayjs();

    // calculate total duration
    var duration = dayjs.duration(endTime.diff(startTime));
    // duration in hours
    var hours = Math.floor(duration.asHours());
    // duration in minutes
    var minutes = Math.floor(duration.asMinutes()) % 60;
    // duration in seconds
    var seconds = Math.floor(duration.asSeconds()) % 60;

    return hours
      ? `${hours} : ` +
          `${minutes > 9 ? minutes : "0" + minutes} : ${
            seconds > 9 ? seconds : "0" + seconds
          }`
      : `${minutes > 9 ? minutes : "0" + minutes} : ${
          seconds > 9 ? seconds : "0" + seconds
        }`;
  }, []);

  const calculateTime = useCallback((startTime: Date | string) => {
    const duration = dayjs.duration(dayjs().diff(dayjs(startTime)));
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${minutes.toString().padStart(2, "0")} : ${seconds
      .toString()
      .padStart(2, "0")}`;
  }, []);

  useEffect(() => {
    if (load) {
      setShowTime(() => passedTime(data.ordered_date));
      if (processStartTimeRef.current) {
        setProcessTime(calculateTime(processStartTimeRef.current));
      } else if (data.process_start_date) {
        setProcessTime(calculateTime(data.process_start_date));
      }
      setTime((time) => time + 1);
      setLoad(false);
    }
    if (!load) {
      tick.current = setTimeout(() => {
        setShowTime(() => passedTime(data.ordered_date));
        setProcessTime(() => passedTime(data.process_start_date));
        setTime((time) => time + 1);
      }, 1000);
    }

    return () => {
      setLoad(true);
      clearInterval(tick.current);
    };
  }, [time, data]);

  useEffect(() => {
    const bellTime = showTime.split(":");
    if (1 > Number(bellTime[0]) && 9 >= Number(bellTime[1]) && !bell) {
      setBell(true);
    } else if (Number(bellTime[1]) > 10 && bell) {
      setBell(false);
    }
  }, [bell, showTime]);

  //벨소리 컨트롤 - subkds의 경우, 한번에 여러 아이템이 들어오기때문에, currentTime을 통해서 울리고 있는지 체킹 후, 울리는동안 안울리게.
  useEffect(() => {
    if (bell) {
      if (kdsSettingStore.alarm) {
        audioRef.current?.play();
        (audioRef.current as any).currentTime = 0;
      }

      toast.info("새로운 주문이 들어왔습니다.");
    }
  }, [bell]);

  //확인 (처리 -> 처리 중 상태 변경) - 낙관적 업데이트
  const processItem = useMutation(["processItem"], fetchSubKdsProcess, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(["subKdsListData", areaNumber]);

      const previousData = queryClient.getQueryData<ISubkdsListRes>([
        "subKdsListData",
        areaNumber,
      ]);

      if (!previousData) return { previousData };

      const currentDate = new Date();
      const currentDateString = currentDate
        .toISOString()
        .replace("T", " ")
        .split(".")[0];

      const calculateTotalTime = (item: ISubkdsListItem) => {
        const orderTime = dayjs(item.ordered_date);
        const totalTime = dayjs(currentDate).diff(orderTime, "second");
        return totalTime;
      };

      const updatedList = previousData.list
        .map((item) => {
          if (
            item.receipt_item_contents_idx ===
            variables.receipt_item_contents_idx
          ) {
            if (variables.process_status === 2) {
              // Item is being moved to STATION, so we'll remove it
              return null;
            }
            return {
              ...item,
              process_status: variables.process_status,
              process_start_date:
                variables.process_status === 1
                  ? currentDateString
                  : item.process_start_date,
              totalTime: calculateTotalTime(item),
            };
          }
          return {
            ...item,
            totalTime: calculateTotalTime(item),
          };
        })
        .filter(Boolean) as ISubkdsListItem[]; // Remove null items (those moved to STATION)

      // Sort items based on totalTime, with processing items first
      updatedList.sort((a: any, b: any) => {
        if (a.process_status === 1 && b.process_status !== 1) return -1;
        if (a.process_status !== 1 && b.process_status === 1) return 1;
        return (b.totalTime ?? 0) - (a.totalTime ?? 0);
      });

      const newData = {
        ...previousData,
        list: updatedList,
        total_count: updatedList.length,
      };

      queryClient.setQueryData(["subKdsListData", areaNumber], newData);

      // Update counts
      const infoStatus = {
        ing: updatedList.filter((item) => item.process_status === 1).length,
        wait: updatedList.filter((item) => item.process_status === 0).length,
      };

      return { previousData, infoStatus, currentDate };
    },
    onError: (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ["subKdsListData", areaNumber],
          context.previousData
        );
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["subKdsListData", areaNumber]);

      if (context?.infoStatus) {
        setInfoStatus(context.infoStatus);
      }

      // Force re-render to update time displays
      setTime((prev) => prev + 1);
    },
  });

  const handlerProccessing = useCallback(
    (idx: number, status: number) => {
      processItem.mutate(
        {
          receipt_item_contents_idx: idx,
          process_status: status,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(["subKdsListData", areaNumber]);
          },
        }
      );
    },
    [processItem, queryClient]
  );

  const handleProcessStart = () => {
    if (data.process_status === 0) {
      setProcessTime("00 : 00");
      handlerProccessing(data.receipt_item_contents_idx, 1);
      soundEffectPlay("/sound/next2.mp3");
    }
  };

  const handleRetry = () => {
    if (timer.current !== null) {
      clearTimeout(timer.current); // 이전에 생성된 타이머를 제거
    }
    soundEffectPlay("/sound/retry2.mp3");
    setRetry(true);
    handlerProccessing(data.receipt_item_contents_idx, 1);
    timer.current = setTimeout(() => {
      setRetry(false);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timer.current !== null) {
        clearTimeout(timer.current); // 컴포넌트가 언마운트되는 시점에 타이머 제거
      }
    };
  }, []);

  const handleFin = () => {
    handlerProccessing(data.receipt_item_contents_idx, 2);
    soundEffectPlay("/sound/final.mp3");
  };

  //사용, 폐지
  const processPrep = useMutation(["processPrep"], (request: IPrepProcessReq) =>
    fetchPrepProcess(request)
  );

  const handlePrepConfirm = (type: string) => {
    handlerProccessing(data.receipt_item_contents_idx, 2);
    const prep_management_idx = (processItem as any).data.prep_management_idx;

    console.log("processItem", processItem);

    if (prep_management_idx === 0) {
      alert(
        "조리가 시작된 후 프랩을 생성한 경우, 프랩을 사용하실 수 없습니다."
      );
    }

    if (type === "prepUse" && !!prep_management_idx) {
      console.log("prep_management_idx", prep_management_idx);

      prepListStore.removePrep(data.product_name);
      processPrep.mutate(
        {
          list: [{ prep_management_idx, use_status: 1 }],
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(["prepManagement"]);
            queryClient.invalidateQueries(["prepCategoryList"]);
            queryClient.invalidateQueries(["subKdsListData"]);
            console.log("프랩 사용 성공");
          },
          onError: (err) => {
            prepListStore.addPrep(data.product_name);
            console.error(err);
          },
        }
      );
    }
  };

  const generateSaleType = useCallback((sale_type: number) => {
    return sale_type === 0 ? "내점" : sale_type === 1 ? "포장" : "배달";
  }, []);

  const generateAddOptionBadge = (item: string) => {
    return item.includes("치즈")
      ? "badge_cheese"
      : item.includes("무스")
      ? "badge_mousse"
      : item.includes("샷 추가")
      ? "badge_drink"
      : "badge_default";
  };

  const availFrepChk = () => {
    if (data.product_name.includes("피자")) {
      return (
        areaNumber === 1 &&
        (
          Object.values(toJS(prepListStore.prepCategoryLists))
            .flat()
            .find((item: any) => item.product_name === data.product_name) as any
        ).count
      );
    } else {
      return (
        Object.values(toJS(prepListStore.prepCategoryLists))
          .flat()
          .find((item: any) => item.product_name === data.product_name) as any
      ).count;
    }
  };

  return (
    <>
      <ReceiptWrap
        className={`${data.process_status === 1 ? "fin" : "default"}`}
        onClick={handleProcessStart}
      >
        <div className={`info_head`}>
          <div className="wrap_info">
            <div className="recepit_id">NO.{data.receipt_number}</div>
            <div className="item_name">
              <span className="txt">{data.product_name} </span>
            </div>
            <div className="time">
              {data.process_start_date === "" || data.process_status === 0 ? (
                <span className="timeBox">
                  <span className="txt_time2">
                    <span className="txt">대기</span> :{" "}
                    <span className="time">{showTime}</span>
                  </span>
                </span>
              ) : (
                <span className="timeBox">
                  <span className={`txt_time1 ${retry ? "on" : "off"}`}>
                    <span className="txt">조리</span>
                    <span className="time">
                      {processTime.substring(0, 1) !== "9"
                        ? processTime
                        : "00 : 00"}
                    </span>
                  </span>
                  <span className="bar" />
                  <span className="txt_time2">
                    <span className="txt">총합</span>
                    <span className="time">{showTime}</span>
                  </span>
                </span>
              )}
            </div>
          </div>
          <div
            className={`wrap_saleType  ${
              showTime.length > 7 || Number(showTime.substring(0, 2)) >= 30
                ? "alert"
                : 30 > Number(showTime.substring(0, 2)) &&
                  Number(showTime.substring(0, 2)) > 20
                ? "warning"
                : "safe"
            } `}
          >
            <span className="txt">{generateSaleType(data.sale_type)}</span>
          </div>
        </div>
        <div className="cont">
          {/* <div className="wrap_badge">
          {data.add_option_list?.split(",").map((el, i) => (
            <span key={i} className={`badge ${generateAddOptionBadge(el)}`}>
              {el.includes("치즈") ? "치즈" : el}
            </span>
          ))}
        </div> */}
          {data.process_status === 0
            ? data.is_btn_hide === 0 && (
                <div className="btn_box">
                  {availFrepChk() ? (
                    <span className="badge_prep">PREP</span>
                  ) : (
                    ""
                  )}
                  <button className="btn_start">조리 시작</button>
                </div>
              )
            : data.is_btn_hide === 0 && (
                <div className="btn_box">
                  <button
                    className={`btn_retry ${retry ? "on" : "off"}`}
                    onClick={handleRetry}
                  >
                    <span className="hiddenZoneV">재처리</span>
                  </button>
                  {data.is_prep === 1 && (
                    <span className="badge_prep">PREP</span>
                  )}
                  <RippleButton
                    onClick={() => {
                      if (availFrepChk()) {
                        setShowPrepConfirm(true);
                      } else {
                        handleFin();
                      }
                    }}
                    areaNumber={areaNumber}
                  >
                    {areaNumber === 1 ? "STATION 이동" : "조리 완료"}
                  </RippleButton>
                </div>
              )}
        </div>
        <audio src="/sound/bell.mp3" id="myAudio" ref={audioRef} loop={false}>
          오디오 지원되지 않는 브라우저
        </audio>
      </ReceiptWrap>
      {showPrepConfirm && (
        <>
          <Dimm
            onClick={(e) => {
              e.stopPropagation();
              setShowPrepConfirm(false);
            }}
          />
          <Popup className="pop_prep">
            <button
              className="btn_close_pop"
              onClick={() => setShowPrepConfirm(false)}
            >
              <span className="hiddenZoneV">닫기</span>
            </button>
            <div className="content">
              <div className="title">PREP 사용확인</div>
              <div className="txt">PREP을 사용하시겠습니까?</div>
            </div>
            <div className="btn_box">
              <button
                onClick={() => handlePrepConfirm("prepUnUse")}
                disabled={processItem.isLoading}
              >
                아니요
              </button>
              <button
                onClick={() => handlePrepConfirm("prepUse")}
                disabled={processItem.isLoading}
              >
                예
              </button>
            </div>
          </Popup>
        </>
      )}
    </>
  );
}

export default observer(Receipt);
