import styled from "@emotion/styled/macro";

export const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  h1 {
    width: 19.3rem;
    height: 10.9rem;
    background: url(/images/logo_home.svg) no-repeat left top;
    background-size: 19.3rem 10.9rem;
  }

  .txt {
    margin: 3.2rem 0 17.3rem;
    color: #ff4600;
    font-family: "Montserrat";
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
  }

  input {
    display: block;
    width: 39rem;
    height: 4.5rem;
    margin-bottom: 1.6rem;
    padding: 1.6rem 2rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.2px;
    color: #828282;
    border: 1px solid #bdbdbd;
    border-radius: 0.4rem;
    appearance: none;
    transition: border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;

    &:focus {
      outline: 0;
      border-color: #ff862c;
      box-shadow: 0 0 0 0.25rem rgba(255, 134, 44, 0.25);
    }
  }

  .btn_login {
    width: 39rem;
    height: 5.2rem;
    margin-bottom: 14.8rem;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    background: #ff4600;
    border-radius: 4px;
  }

  .copy_right {
    margin-top: 14.8rem;
    font-size: 1.2rem;
    text-align: center;
    letter-spacing: 0.2px;
    color: #828282;
  }
`;

export const BtnHeadOpen = styled.button`
  position: relative;
  z-index: 200;
  display: block;
  width: 13.5rem;
  height: 5.6rem;
  margin: 0 auto;
  border-radius: 0 0 4rem 4rem;
  background: #ff4600;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.4rem;
    height: 2.4rem;
    background: url("/images/arrow_header_down.svg") no-repeat left top;
  }
`;

export const Header = styled.header`
  position: relative;
  z-index: 200;
  display: flex;
  width: 100vw;
  height: 5.2rem;
  padding: 0 2.4rem;
  align-items: center;
  color: #fff;
  font-size: 1.6rem;
  border-bottom: 1px inset #444;
  background: #2e3237;

  .left,
  .right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .left .status,
  .right .menu {
    display: flex;
  }

  .left {
    font-family: "Montserrat";
    font-weight: 700;

    .box_page_info {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 3.2rem;

      .wrap_page_number {
        margin: 0 2.4rem;
      }

      .btn_page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 0.4rem;
        background: #3c3f42;

        &:hover {
          background: #ff4600;
        }

        &.btn_next {
          &:before {
            transform: rotate(180deg);
          }
        }

        &:before {
          content: "";
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          background: url("/images/arrow_head_page.svg") no-repeat center;
          background-size: contain;
        }
      }

      .current {
        color: #fff;
        font-size: 2.4rem;
      }

      .bar {
        margin: 0 0.4rem;
      }

      .bar,
      .total {
        color: #9d9d9d;
        font-size: 1.6rem;
      }
    }

    .status {
      li {
        display: flex;
        align-items: center;
        margin-right: 3.2rem;

        &:last-of-type {
          margin-right: 0;
        }
        .txt {
          font-weight: normal;
        }
        .num {
          margin-left: 0.5rem;
          color: #ff4600;
        }
      }
    }
  }

  .right {
    margin-left: auto;
    .menu {
      display: flex;

      li {
        margin-right: 6rem;
        &.on {
          color: #ff4600;
        }
      }
    }
    .area_btn {
      &.on {
        button {
          color: #ff4600;
        }
      }

      button {
        color: #fff;
        font-size: 1.6rem;
        background: transparent;
      }
    }
  }
`;

export const Dimm = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

export const SubKdsContent = styled.div`
  padding: 1.6rem;
  background: #131313; //E0E0E0  //#061138

  .noOrder {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 8rem);
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;

    .title {
      margin: -65px 0 30px;
    }
  }

  .swiper {
    height: 100%;
  }
`;

export const ReceiptWrap = styled.div`
  border-radius: 4px;
  background: #222222;

  position: relative;
  overflow: hidden;
  height: max-content;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &.showNone {
    display: none;
  }

  &.on {
    opacity: 0.5;
  }

  /* &.fin {
    .cont * {
      color: #fff;
    }
    background: #221e1e;
  } */

  .cont.on {
    color: #fff;
    background: #000;
  }

  .info_head {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 1.6rem;
    padding: 1.6rem 0;
    border-bottom: 1px solid #3c3f42;

    .recepit_id {
      display: block;
      color: #969492;
      font-size: 1.6rem;
      font-family: "Montserrat";
      line-height: 1;
    }
    .item_name {
      margin: 0.8rem 0 1.6rem;
      color: #fff;
      font-size: 1.8rem;
      line-height: 1;
    }
    .time {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
    }
    .timeBox {
      display: flex;
      align-items: center;

      .txt_time1,
      .txt_time2 {
        display: flex;
        align-items: center;

        .time {
          margin-left: 0.8rem;
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 1.6rem;
        }
      }

      .txt_time1 {
        min-width: 9.5rem;
        color: #ffbc2c;

        &.on {
          color: #ff4600;
        }
      }

      .txt_time2 {
        margin-left: 1.1rem;
        color: #9d9d9d;
      }

      .bar {
        width: 0.2rem;
        height: 1.7rem;
        background: url("/images/bar_time.svg") no-repeat left top;
      }
    }

    .wrap_saleType {
      /* border-radius: 50%; */
    }
    .wrap_saleType {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 7.8rem;
      height: 7.8rem;
      margin: auto;
      font-size: 2rem;
      font-weight: 700;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: #222222;

      &:before {
        content: "";
        background-image: conic-gradient(#04b0ee 20deg, transparent 120deg);
        height: 150%;
        width: 150%;
        position: absolute;
        left: -25%;
        top: -25%;
      }
      &:after {
        content: "";
        height: 94%;
        width: 94%;
        position: absolute;
        background-color: #1c1b29;
        border-radius: 50%;
        top: 3%;
        left: 3%;
        color: #04b0ee;
        display: grid;
        place-items: center;
        font-size: 20px;
        letter-spacing: 6px;
      }

      .txt {
        position: relative;
        z-index: 10;
      }

      @keyframes rotate {
        100% {
          transform: rotate(-360deg);
        }
      }

      @keyframes blink {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
      }

      &.alert {
        border: none;

        &:before {
          background-image: conic-gradient(#ef4747 20deg, transparent 120deg);
          animation: rotate 1s infinite linear;
        }
        .txt {
          color: #ef4747;
          animation: blink 1.5s infinite;
        }
      }
      &.warning {
        border: none;

        &:before {
          background-image: conic-gradient(#ff862c 20deg, transparent 120deg);
          animation: rotate 2s infinite linear;
        }
        .txt {
          color: #ff862c;
          animation: blink 4s infinite;
        }
      }
      &.safe {
        border: none;

        &:before {
          background-image: conic-gradient(#fff 20deg, transparent 120deg);
          animation: rotate 2.5s infinite linear;
        }
        .txt {
          color: #fff;
        }
      }
    }
  }

  &.default {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: rgba(255, 255, 255, 0.1);
      opacity: 0;
      transform: scale(2);
      transition: opacity 0.5s, transform 0.5s;
    }

    &:active:after {
      opacity: 1;
      transform: scale(0);
      transition: 0s;
    }
    .info_head .wrap_saleType {
      color: #bdbdbd;
      border: 1px solid #333;
      background: #333;
    }
  }

  .cont {
    display: flex;
    align-items: center;
    margin: 0 1.6rem;
    padding-top: 0.8rem;
    color: #4f4f4f;

    .wrap_badge {
      display: flex;
      margin-top: 3rem;
      .badge {
        display: block;
        height: 5.8rem;
        padding: 0 3.2rem;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 5.8rem;
        border-radius: 2.9rem;

        &.badge_cheese {
          color: #ff6d00;
          background: #fff7dd;
        }
        &.badge_mousse {
          color: #6e3dff;
          background: #cdbcff;
        }
        &.badge_drink {
          color: #964b00;
          background: #f2dfd3;
        }
      }
    }

    .item_name {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.6rem;

      .btn_skip {
        width: 3.2rem;
        height: 3.2rem;
        margin-left: 1.5rem;
        background: url(/images/ico_skip.svg) no-repeat left center;
        background-size: 2.5rem;
      }
    }
    .time {
      margin: 0.8rem 0 1.6rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
    .timeBox {
      display: flex;
      span:first-of-type {
        display: flex;
        align-items: center;

        &:after {
          content: "";
          width: 1px;
          height: 10px;

          margin: 0 15px;
          background: #bdbdbd;
        }
      }
    }

    .btn_start {
      width: 56%;
      height: 4.8rem;
      margin: 0 0 0 auto;
      color: #fff;
      font-weight: bold;
      font-size: 1.6rem;
      border: 1px solid #ff4600;
      border-radius: 0.4rem;
      background: transparent;
    }

    .badge_prep {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5.2rem;
      height: 2.2rem;
      margin: 0 auto;
      color: #ffbc2c;
      font-size: 1.4rem;
      font-weight: medium;
      border: 1px solid #ffbc2c;
      border-radius: 2rem;
    }

    .btn_box {
      display: flex;
      align-items: center;
      /* justify-content: center; */
      margin: auto 0 1.6rem 0;
      width: 100%;

      /* button {
        width: 17.9rem;
        height: 9rem;
        text-align: center;
        font-weight: 700;
        line-height: 9rem;
        border-radius: 0.8rem;
        font-size: 3.6rem;
        &:disabled {
          opacity: 0.7;
        }
      } */

      button:nth-of-type(2) {
        margin-left: auto;
      }
      .btn_skip {
        width: 6rem;
        margin-right: 0.8rem;
        color: #221e1e;
        background: #fff;
      }
      .btn_fin {
        color: #fff;
        margin: 0 3.4rem 0 auto;
        background: #5ea152;
        border: none;
        cursor: pointer;
        outline: none;
        transition: background-color 0.3s, transform 0.3s;

        &:active {
          background-color: #3f7a3a;
          transform: translateY(2px);
        }
      }
      .btn_retry {
        position: relative;
        overflow: hidden;
        width: 3.2rem;
        height: 3.2rem;
        background: url("/images/btn_restart.svg") no-repeat left top / 100%;
        transition: transform 0.1s ease-in-out;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.3);
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s ease;
          border-radius: 50%;
        }
        &:hover::before {
          opacity: 1;
        }
        &:active::before {
          opacity: 0;
          transition: opacity 0s;
        }

        &:active {
          transform: scale(0.7);
        }
        &.on {
          animation: RetryRotate 0.3s ease-out;
        }
      }
      @keyframes RetryRotate {
        100% {
          transform: rotate(360deg);
        }
      }

      .btn_cancle {
        margin-right: 0.8rem;
        color: #828282;
        border: 1px solid #828282;
        background: transparent;
      }
      .btn_submit {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 50%;
        color: #fff;
        background: #2f80ed;
        &.ing:after {
          content: "";
          display: inline-block;
          width: 2rem;
          height: 2rem;
          background: url("/images/spinner_waiting.gif") no-repeat center;
          background-size: 2rem;
        }
      }
    }
  }
`;

export const Popup = styled.div`
  z-index: 500;
  &.pop_prep {
    display: flex;

    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 85rem;
    max-heihgt: 53.5rem;
    transform: translate(-50%, -50%);
    border-radius: 0.8rem;
    background: #3c3f42;

    .btn_close_pop {
      position: absolute;
      top: 3.2rem;
      right: 4.6rem;
      width: 3.6rem;
      height: 3.6rem;
      background: url("/images/ico_close_pop.svg") no-repeat left top;
    }

    .content {
      padding: 5rem 3.2rem;
      border-bottom: 1px solid #4f4f4f;

      .title,
      .txt {
        font-family: "Montserrat";
        text-align: center;
      }

      .title {
        margin-bottom: 2.4rem;
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 4.8rem;
        color: #fff;
      }
      .txt {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 3.2rem;
        color: #c9c9c9;
      }
    }

    .btn_box {
      margin: 5.6rem auto 3.2rem;
      button {
        width: 37.7rem;
        height: 9.6rem;
        margin-right: 30px;
        font-size: 3.6rem;
        color: #fff;
        border-radius: 0.4rem;

        &:first-of-type {
          background: #2e3237;
        }
        &:last-of-type {
          background: #ff9067;
        }
      }
    }
  }
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 1000;
  width: 70px;
  height: 36px;
  margin-left: -35px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-radius: 36px;
  background: #222222;
`;

export const SlidePageWrap = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 100%;
  background: #131313;
`;

export const SubKdsWrap = styled.div`
  position: relative;
  height: 100vh;
  background: #131313;
  @media (min-width: 1200px) and (max-width: 1500px) {
    ${SlidePageWrap} {
      padding-bottom: 7rem;
    }
  }
`;

export const LayerConfirmWrap = styled.div`
  &:before {
    content: "";
    position: fixed;
    z-index: 1001;
    top: 10rem;
    left: 0;
    width: 100vw;
    height: calc(100vh - 10rem);
    background: rgba(0, 0, 0, 0.7);
  }
  .popup {
    position: fixed;
    z-index: 1002;
    top: 50%;
    left: 50%;
    width: 50.8rem;
    transform: translate(-50%, -50%);
    padding: 3.6rem 2.4rem 2.4rem;
    color: #4f4f4f;
    background: #ffffff;
    box-shadow: 0px 0.8rem 2.2rem rgba(0, 0, 0, 0.25);
    border-radius: 0.4rem;

    h1 {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.6rem;
    }

    p {
      min-height: 4.8rem;
      margin: 0.8rem 0 2.4rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.3rem;
    }

    .box_btn {
      display: flex;
      justify-content: flex-end;

      button {
        width: 8.4rem;
        height: 4rem;
        font-size: 1.6rem;
        line-height: 4rem;
        text-align: center;
        border-radius: 0.4rem;

        &.btn_cancle {
          margin-right: 1rem;
          color: #828282;
          border: 1px solid #828282;
          background: #fff;
        }

        &.btn_confirm {
          color: #fff;
          background: #2f80ed;
        }
      }
    }
  }
`;

export const PrepManagementWrap = styled.div`
  .head {
    display: flex;
    justify-content: center;
    position: relative;
    height: auto !important;
    padding: 4.8rem 2rem 3.2rem 2rem;
    border-bottom: 1px solid #4f4f4f;

    h2 {
      text-align: center;
    }
    button {
      position: absolute;
      left: 2rem;
    }
  }

  .status {
    display: flex;
    align-items: center;
    height: 6.9rem;
    padding: 1.8rem 2.4rem;
    color: #333;
    font-size: 2rem;
    font-weight: 70rem;
    line-height: 2.9rem;
    background: #f2f2f2;

    .number {
      margin-left: auto;
      color: #ff4600;
    }
  }

  .control {
    display: flex;
    align-items: center;
    padding: 2.4rem;
    border-bottom: 1px solid #4f4f4f;
    .title {
      color: #fff;
      font-size: 2rem;
      line-height: 2.9rem;
    }
    .btn_area {
      margin-left: auto;
    }
  }
  button {
    width: 7.7rem;
    height: 3.3rem;
    line-height: 3.3rem;
    border-radius: 2px;
    background: #f2f2f2;

    &.add_btn .txt {
      color: #2f80ed;
      background: url("/images/ico_prep_add.svg") no-repeat left center;
    }
    &.add_confirm {
      margin-left: 2rem;
      .txt {
        color: #bdbdbd;
        background: url("/images/ico_prep_confirm.svg") no-repeat left center;
      }
      &.on {
        .txt {
          color: #fff;
          background: url("/images/ico_prep_confirm_white.svg") no-repeat left
            center;
        }
        background: #2f80ed;
      }
    }
    .txt {
      padding-left: 2.3rem;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .list_prep {
    li {
      display: flex;
      align-items: center;
      padding: 2.4rem;
      font-size: 1.6rem;
      border-bottom: 1px solid #4f4f4f;

      .button_area {
        margin-left: auto;

        .btn_use,
        .btn_trash {
          width: 7.7rem;
          height: 3.3rem;
          line-height: 3.3rem;
          border-radius: 2px;
          background: transparent;

          &:hover {
            color: #fff;
          }
        }
        .btn_use.on,
        .btn_trash:disabled {
          color: #bdbdbd;
          border: 1px solid #bdbdbd;

          &:hover {
            color: #333;
            background: #bdbdbd;
          }
        }
      }

      .btn_use {
        margin-right: 1rem;
        color: #2f80ed;
        border: 1px solid #2f80ed;

        &:hover {
          background: #2f80ed;
        }
      }
      .btn_trash {
        color: #ef4747;
        border: 1px solid #ef4747;

        &:hover {
          background: #ef4747;
        }
      }
    }
  }
`;

export const PrepStatusWrap = styled.div`
  .top {
    .list_frip_menu {
      display: grid;
      align-items: center;
      gap: 1.6rem;
      grid-template-columns: 1fr 1fr;
      padding: 2rem;
      border-bottom: 1px solid #4f4f4f;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6.4rem;
        color: #fff;
        font-size: 1.6rem;
        background: #393939;

        &.on {
          border: 4px solid #ff4600;
        }
      }
    }
  }

  .content {
    overflow-y: auto;
    max-height: calc(100vh - 28.6rem);
  }
  .list_prep_items {
    width: 100%;
    th,
    td {
      height: 5.6rem;
      font-size: 1.6rem;
      font-weight: bold;

      &:first-of-type {
        padding-left: 2.4rem;
        text-align: left;
      }
      &:last-of-type {
        padding-right: 2.4rem;
        text-align: right;
      }
    }
    tr {
      &:last-of-type td {
        border-bottom: 1px solid #4f4f4f;
      }
    }
    th {
      color: #9d9d9d;
    }
    td {
      border-top: 1px solid #4f4f4f;
      &.quanity {
        .num,
        .txt {
          display: inline-block;
          vertical-align: middle;
        }
        .num {
          margin-right: 1rem;
          font-size: 2rem;
          font-weight: medium;
          color: #ff4600;
        }
        .txt {
          font-size: 1.6rem;
          font-weight: 300;
          color: #9d9d9d;
        }
      }
    }
  }
`;
